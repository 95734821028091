<template>
  <div class="main-content">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-tabs
      active-nav-item-class="nav nav-tabs"
      content-class="mt-3"
    >
      <b-tab title="Statistics" active>
        <b-row >
          <b-col lg="3" md="3" sm="3">
            <b-card class="card-icon text-center mb-30">
              <i class="i-Arrow-Down"></i>
              <p class="text-muted mt-2 mb-2">Redeemable Value </p>
              <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsValue }}</p>
            </b-card>
          </b-col>
          <b-col lg="3" md="3" sm="3">
            <b-card class="card-icon text-center mb-30">
              <i class="i-Arrow-Down"></i>
              <p class="text-muted mt-2 mb-2">Total Points Accrued (Pending) </p>
              <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsAccrued }}</p>
            </b-card>
          </b-col>
          <b-col lg="3" md="3" sm="3">
            <b-card class="card-icon text-center mb-30">
              <i class="i-Arrow-Down"></i>
              <p class="text-muted mt-2 mb-2">Points Accrued Today</p>
              <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsAccruedToday }}</p>
            </b-card>
          </b-col>
          <b-col lg="3" md="3" sm="3">
            <b-card class="card-icon text-center mb-30">
              <i class="i-Arrow-Up"></i>
              <p class="text-muted mt-2 mb-2">Redeemed Today</p>
              <p class="text-primary text-24 line-height-1 m-0"> {{ merchantStats.redeemedToday }}</p>
            </b-card>
          </b-col>
        </b-row>

        <hr/>
        <div class="row">
          <div class="col-md-6">
            <h4>Filters:</h4>
          </div>
          <div class="col-md-6">
            <button style="margin-left: 10px;" type="button" class="btn btn-primary float-right" @click="clearFilter">Clear All</button>
          </div>
        </div>

        <div class="row" style="margin-bottom: 20px;">
          <div class="col-md-6">
            <div>
              By date:
            </div>

            <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" @click="getTransactionsData">Filter</button>

          </div>
          <div class="col-md-2">
            <div>
              By Transaction type:
            </div>
            <select class="form-control" v-model="transaction_type" @change="getTransactionsData">
              <option value="all"> All </option>
              <option :value="t_type" v-for="t_type in transaction_types">{{ t_type }}</option>
            </select>
          </div>

        </div>

        <br/>

        <b-table striped hover :items="transactions" :fields="columns">

          <template v-slot:cell(customer)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/customer/'+data.value.id">{{ data.value.name }}</a>
          </template>

          <template v-slot:cell(outlet)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>KES. {{ data.value }}</span>
          </template>
          <template v-slot:cell(transactionType)="data">
        <span  class="badge badge-danger"  v-if="data.value == 'ACCRUAL'">
							Accrual
						</span>
            <span class="badge badge-success"  v-else >
							Redemption
        </span>
          </template>

          <template v-slot:cell(transactionApprovalStatus)="data">
            <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'APPROVED'" >
								Accepted
							</span>
              <span  class="badge badge-warning"  v-else-if="data.value == 'PENDING'" >
								Pending
							</span>

              <span class="badge badge-danger"  v-else >
								Reversed
							</span>
            </div>
            <div v-else>
							<span   class="badge badge-info" >
								Accepted
							</span>
            </div>
          </template>

          <template v-slot:cell(options)="data">

            <b-dropdown text="options">
              <b-dropdown-item href="#" v-if="data.item.transactionApprovalStatus == 'PENDING'" @click="openReverseTransactionModal(data.item)">Reverse</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </b-tab>
      <b-tab title="Invoices" >
        <!--<div class="row">
            <div class="col-md-12">
                 <a class="btn btn-danger float-right" href="#" @click="generateInvoiceData">Generate Invoice</a>
            </div>
        </div>-->
        <b-table striped hover :items="invoices" :fields="column_invoices">
          <template v-slot:cell(invoiceNum)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/invoice/'+data.item.id">{{ data.value }}</a>
          </template>

          <template v-slot:cell(outlet)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(invoiceAmount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

          <template v-slot:cell(duration)="data">
            {{ data.item.invoiceStartDate }} - {{ data.item.invoiceEndDate }}
          </template>




          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="options">
              <!--<b-dropdown-item href="#" @click="openReverseTransactionModal(data.item)">Reverse</b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'

    export default {
        name: "outletProfile",

        data(){
          return {
            outlet_id:"",
            invoices:[],
            columns:["customer","phoneNumber","outlet","amount","transactionDate","loyaltyPoints","transactionType","transactionApprovalStatus","narration","options"],
            column_invoices:["invoiceNum","invoiceDate","outlet","duration","invoiceAmount"],
            transactions:[],
            isLoading: false,
            fullPage: true,
            pagination: {
              total: 0,
              per_page: 2,
              from: 1,
              to: 0,
              current_page: 0
            },
            start_date:"all",
            end_date:"all",
            offset: 4,
            merchantStats:{
              pointsValue:0,
              pointsAccrued:0,
              pointsAccruedToday:0,
              redeemedToday:0
            },
            transaction_type:"all",
            transaction_types:["Accrual","Redemption"],
            invoice_start_date:"2020-10-01",
            invoice_end_date:"2021-01-19",
            approval_type:"ALL",
            loyaltyRuleId:0,
          }
        },
        computed:{
          isActived: function () {
            return this.pagination.current_page + 1;
          },
          pagesNumber: function () {
            if (!this.pagination.to) {
              return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
              from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
              to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
              pagesArray.push(from);
              from++;
            }
            return pagesArray;
          },
          permissions(){
            return localStorage.getItem("permissions");
          },
          role(){
            return localStorage.getItem("role");
          }
        },
        components:{
          Loading,
          DatePicker,
          Multiselect
        },
        mounted(){
          this.outlet_id = this.$route.params.id;

          const query = this.$route.query;

          if(query.start_date) {
            this.start_date = query.start_date;
          }

          if(query.end_date) {
            this.end_date = query.end_date;
          }

          this.getMerchantLoyaltyStatsData();

          this.getTransactionsData();

          this.getInvoiceByMerchant();


        },
         methods:{
           ...mapActions(["getLoyaltyTransactions", "getMerchantStats","generateInvoice","getInvoiceByOutletId"]),
           changePage: function (page){
             this.pagination.current_page = page;
             this.getTransactionsData();
           },
           getInvoiceByMerchant(){

             let self = this;

             self.isLoading = true;

             this.getInvoiceByOutletId({outletId: this.$route.params.id})


               .then(function (invoices) {

                 self.isLoading = false;

                 self.invoices = invoices;


               })
               .catch(function (error) {

                 self.isLoading = false;

                 if( typeof error.response.status != "undefined"){
                   if(error.response.status == 401){
                     location.replace("/login");
                   }

                 }
               })
           },
           getMerchantLoyaltyStatsData(){
             let self = this;


             this.getMerchantStats({id:this.outlet_id})
               .then(function (merchantStats) {
                 self.merchantStats = merchantStats;
               })
               .catch(function (error) {
                 if( typeof error.response.status != "undefined"){
                   if(error.response.status == 401){
                     location.replace("/login");
                   }

                 }
               })
           },
           clearFilter(){
             this.start_date = "all";
             this.end_date = "all";
             this.transaction_type = "all";

             if(this.role != 'Merchant'){
               this.outlet = null;
             }

           },
           getInvoiceData(){
             let self = this;


             this.isLoading = true;


             this.getInvoices()
               .then(function (invoices) {

                 self.isLoading = false;



                 self.invoices = invoices;

               })
               .catch(function (error) {

                 self.isLoading = false;

                 if( typeof error.response.status != "undefined"){
                   if(error.response.status == 401){
                     location.replace("/login");
                   }

                 }
               })
           },
           generateInvoiceData(){
             let self = this;


             this.isLoading = true;


             this.getInvoices({start_date: this.invoice_start_date, end_date:this.invoice_end_date,outletId:this.outletId})
               .then(function () {

                 self.isLoading = false;

                 self.$bvToast.toast("Invoice generated successfully.", {
                   title: 'Success',
                   variant: 'success',
                   autoHideDelay: 5000,
                   appendToast: false
                 });

                 self.getInvoiceData();

               })
               .catch(function (error) {

                 self.isLoading = false;

                 self.$bvToast.toast(error.message, {
                   title: 'Error',
                   variant: 'danger',
                   autoHideDelay: 5000,
                   appendToast: false
                 });

               })
           },
           getTransactionsData(){
             let self = this;

             this.isLoading = true;

             this.getLoyaltyTransactions({page: this.pagination.current_page, from_date:this.start_date,to_date:this.end_date,outlet_id:this.outlet_id, transaction_type:this.transaction_type, approval_type: this.approval_type, loyaltyRuleId:this.loyaltyRuleId})
               .then(function (transactions) {

                 self.isLoading = false;

                 self.transactions = [];

                 let transactions_arr = transactions.content;

                 transactions_arr.forEach(function (transaction) {


                   let single_loyalty_trans = transaction;

                   if(transaction.customer != null){
                     single_loyalty_trans.phoneNumber = transaction.customer.mobileNumber;
                   }
                   else{
                     single_loyalty_trans.phoneNumber = "";
                   }


                   self.transactions.push(single_loyalty_trans)
                 });


                 self.pagination.current_page = transactions.number;
                 self.pagination.total = transactions.totalElements;
                 self.pagination.per_page = transactions.numberOfElements;
                 self.pagination.from = transactions.pageable.offset + 1 ;
                 self.pagination.to = transactions.pageable.pageSize;
                 self.pagination.last_page = transactions.totalPages;
               })
               .catch(function (error) {

                 self.isLoading = false;

                 if( typeof error.response.status != "undefined"){
                   if(error.response.status == 401){
                     location.replace("/login");
                   }

                 }
               })
           },
         }

    }
</script>

<style scoped>

</style>
